<template>
  <div>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField placeholder="Search by name" label="Filter by" :onChange="setKeyword" leftIcon
          class="mb-2 w-inputField" data-testid="search-field" />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} closed assessments out of {{ totalCount }}
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-3 rounded">
        <div class="flex w-1/4">
          <button class="flex flex-row items-center focus:outline-none" @click="() => sort(0)" data-testid="header-1">
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex w-160">
          <button class="flex flex-row items-center focus:outline-none" @click="sort(1)" data-testid="header-2">
            <p class="text-sm-2 text-grey-light mr-0.4">START DATE</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex w-200">
          <button class="flex flex-row items-center focus:outline-none" @click="sort(2)" data-testid="header-2">
            <p class="text-sm-2 text-grey-light mr-0.4">END DATE</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex justify-center w-200">
          <button class="flex flex-row items-center focus:outline-none" @click="sort(3)" data-testid="header-2">
            <p class="text-sm-2 text-grey-light mr-0.4">PARTICIPANTS COUNT</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow justify-center w-1/6">
          <button class="flex flex-row items-center focus:outline-none" @click="sort(4)" data-testid="header-2">
            <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLES COUNT</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow w-160 justify-center items-center ml-2">
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
        </div>
      </div>
      <div>
        <VirtualList style="max-height: 360px; overflow-y: auto" :data-key="'id'" :data-sources="mappedList"
          :data-component="itemComponent" @tobottom="triggerFetch" />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import plus from "@/assets/img/icons/add.svg";

import { mapActions, mapState } from "vuex";

export default {
  name: "ClosedAssessmentsTable",
  components: { VirtualList },
  data: () => ({
    plus,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
    pageSz: 20,
    totalCount: 0,
    sorting: null,
  }),
  computed: {
    ...mapState({
      sellers: (state) => state.companies.sellers,
    }),
    organizationId() {
      return this.$route.params.companyId;
    },
  },
  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
  },
  methods: {
    ...mapActions({
      assignUsers: "adminTeams/assignUsers",
      getClosedAssessments: "companies/getCompanyClosedAssessments",
    }),
    sort(index) {
      this.sorting = null;
      this.pageNr = 0;
      this.mappedList = [];
      this.resetRotationMatrix(index);
      this.rotationMatrix[index] == 1
        ? this.rotationMatrix[index]++
        : this.rotationMatrix[index]--;
      switch (index) {
        case 0:
          this.sorting = { name: this.rotationMatrix[index] };
          break;
        case 1:
          this.sorting = { startDate: this.rotationMatrix[index] };
          break;
        case 2:
          this.sorting = { endDate: this.rotationMatrix[index] };
          break;
        case 3:
          this.sorting = { participants: this.rotationMatrix[index] };
          break;
        case 4:
          this.sorting = { jobroles: this.rotationMatrix[index] };
          break;
        default:
          break;
      }
      this.triggerFetch();
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalCount / this.pageSz)) {
        this.pageNr++;
        this.updateList();
      }
    },
    updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        organizationId: this.organizationId,
        keyword: this.keyword,
        pageNr: this.pageNr,
        pageSz: this.pageSz,
        sorting: this.sorting,
      };
      this.getClosedAssessments(payload).then((rsp) => {
        this.totalCount = rsp.totalCount;
        let map = rsp.values.map((item, idx) => ({
          ...item,
          index: (this.pageNr - 1) * this.pageSz + idx,
        }));
        this.mappedList = [...this.mappedList, ...map];
      });
    },
  },
};
</script>
