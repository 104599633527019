<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-3 items-center">
      <div class="flex w-1/4">
        <p
          class="flex flex-grow text-md text-grey-dark-1 font-semibold pr-1"
        >
          {{ source.name }}
        </p>
      </div>
      <div class="flex w-160">
        <p class="text-md text-grey-dark-2">
          {{ formatDate(source.startDate) }}
        </p>
      </div>
      <div class="flex w-200">
        <p class="text-md text-grey-dark-2">
          {{ formatDate(source.endDate) }}
        </p>
      </div>
      <div class="flex w-200 justify-center">
        <p class="text-md text-grey-dark-2">
          {{ source.participantsCount }}
        </p>
      </div>
      <div class="flex w-1/6 justify-center">
        <p class="text-md text-grey-dark-2">
          {{ source.jobRolesCount }}
        </p>
      </div>

      <div class="flex flex-grow w-160 ml-2">
        <Button
          text="View Summary"
          type="secondary"
          size="medium"
          @click.native="viewSummary"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Button from '../../../../components/Button/Button.vue';
import moment from "moment";

export default {
  components: { Button },
  name: 'ListItem',
  props: {
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
  },
  methods: {
    ...mapActions({ 
      continueDraft: 'assessmentSetup/continueDraft',
      setAssessmentTitle: 'assessmentSetup/setAssessmentTitle',
      setFlowMode: 'assessmentSetup/setFlowMode',
      setShouldEdit: 'assessmentSetup/setShouldEdit'
     }),
    async viewSummary() {
      await this.continueDraft({ id: this.source.id });
      this.$router.push(`/company/${this.$route.params.companyId}/old-assessment/${this.source.id}`);
    },
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
  },
};
</script>
